import React from "react";
import { Header } from "../components/header";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { maxMedia, MaxWidth, minMedia, READ_WIDTH, theme } from "../utils/style";
import { useStaticQuery, graphql, Link } from "gatsby";
import RTF from "../components/rtf";
import { CaptionText } from "../components/presse-kontakt";
import { format } from "date-fns";
import styled from "styled-components";
import { FaArrowLeft, FaBackward } from "react-icons/fa";
import Disqus from "gatsby-plugin-disqus/components/Disqus";
import { ROOT } from "../utils/const";
import { slugify } from "../utils/slugify";

const Div = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -12rem;
  background: white;
  padding: 2rem;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);

  ${maxMedia.phone`
        padding: 1rem;
    `}

  .playbutton {
    display: flex;
    align-items: center;

    font-size: 0.85rem;
    color: ${theme.primaryColor};

    margin-top: -1rem;
    margin-bottom: 1rem;

    > *:first-child {
      margin-right: 0.5rem;
    }
  }
`;

const BlogEntry = ({ pageContext }) => {
  const x = pageContext.node;

  return (
    <Layout>
      <SEO title={`Blog | ${x.title}`} />
      <Header small backgroundImg={require("../images/blog.jpg")}></Header>
      <MaxWidth greyGradient maxWidth={READ_WIDTH}>
        <Div style={{ paddingTop: "3rem" }}>
          <Link to={"/blog"} className="playbutton">
            <FaArrowLeft />
            Zurück
          </Link>
          <div className="text">
            <h3 style={{ marginBottom: 0 }}>{x.title}</h3>
            <CaptionText>{format(x.releaseDate, "YYYY-MM-DD")}</CaptionText>
            <p style={{ marginTop: "3rem" }}>
              <RTF content={x.content ? x.content.json : "..."} />
            </p>
          </div>

          <Disqus
            config={{
              /* Replace PAGE_URL with your post's canonical URL variable */
              url: `${ROOT}/blog/${slugify(`${x.title}-${x.id}-${`${x.releaseDate}`.replace(/T.*/, "")}`)}`,
              /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
              identifier: x.id,
              /* Replace PAGE_TITLE with the title of the page */
              title: x.title
            }}
          />
        </Div>
      </MaxWidth>
    </Layout>
  );

  return;
};

export default BlogEntry;
